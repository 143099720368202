.widgetNavCard {
  background-color: rgba(256, 256, 256, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1rem;
  border-radius: 6px;
  //color: #fff;
  margin: 2rem auto;

  .iconArea {
    height: 30px;
    width: 30px;
    border-radius: 6px;
    background: rgba(40, 64, 101, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #7f52ff;
    margin-right: 1rem;
  }

  .title {
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
  }
  .subtitle {
    font-size: 1rem;
    line-height: 24px;
  }
  .actionIcon {
    display: block;
    color: #000000;
    visibility: hidden;
    font-size: 1.2rem;
    margin-left: 1rem;
  }

  &:hover {
    cursor: pointer;
    background-color: #fff;

    .title {
      color: #713fff;
    }
    .subtitle {
      color: #3a434b;
    }
    .actionIcon {
      visibility: visible;
    }
  }
}
