.widgetErrorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: 75%;
  background-color: #fd7e14;

  h3 {
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
  }

  button {
    cursor: pointer !important;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    color: #fd7e14;
    background-color: #fff;
  }
}
