.widgetLoader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #1393ea;
  height: 75%;
  width: 75%;
  background-color: #fff;
  font-size: 1.5rem;
}
