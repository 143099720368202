.widgetCustomTitle {
  margin-bottom: 2rem;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    .title {
      width: 80%;
      max-width: 80%;
      font-weight: bold;
      font-size: 1.5rem;
      margin-right: 1rem;
    }

    .progressBar {
      height: 50px;
      width: 50px;
    }
  }
}
