.alignTextAndLogo {
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  color: #3a434b;
  font-size: 1rem;

  img {
    min-height: 12px !important;
    max-height: 12px !important;
    width: auto;
    margin-right: 1rem;
  }
}
