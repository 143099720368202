.widgetMainWrapper {
  display: flex;
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: hidden;

  .widgetWrapper {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;

    .cancelIcon {
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
      height: 30px;
      width: 30px;
      z-index: 9999999;
    }

    .widget {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #0d0f11;
      height: auto;
      max-height: 75%;
      width: 30%;
      background-color: #fff;
      border-radius: 24px;
      padding: 1.5rem;
      overflow-y: auto;

      @media only screen and (max-width: 768px) {
        max-height: 60%;
        width: 80%;
      }

      @media only screen and (max-width: 576px) {
        width: 90%;
      }

      &::-webkit-scrollbar-track {
        border: 1px solid #713fff;
        border-radius: 5px;
        background-color: #fff;
      }

      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #713fff;
      }

    }

    .widgetMobile{
      //font-size: 1rem !important;
      //display: flex;
      //flex-direction: column;
      //align-items: center;
      //color: #0d0f11;
      //height: auto;
      //max-height: 75%;
      //width: 95%;
      background-color: #fff;
      border-radius: 24px;
      padding: 1rem;
      overflow-y: auto;

      @media only screen and (max-width: 768px) {
        max-height: 60%;
        width: 80%;
      }

      @media only screen and (max-width: 576px) {
        width: 90%;
      }

      &::-webkit-scrollbar-track {
        border: 1px solid #713fff;
        border-radius: 5px;
        background-color: #fff;
      }

      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #713fff;
      }
    /*  h5, .h5 {
        font-size: 1rem;
      }
      h4, .h4 {
        font-size: 1.5rem;
      }*/

    }


    .cancelIconMobile svg{
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
      height: 2rem !important;
      width: 2rem !important;
      z-index: 9999999;
    }
  }
}

#pin {
  background: #ffffff !important;
  border: 1px solid #d7dce0 !important;
  border-radius: 10px !important;
}

.icon, .iconMobile{
  width: 34px !important;
  height: 34px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}


/*.iconMobile {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    width: 3rem !important;
    height: 3rem !important;
  }
}*/


#advancly-button{
  background: red;
}


